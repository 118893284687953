import React from "react"
import { Text } from "../components/common"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout.ColumnLayout>
    {/* <SEO title="404: Not found" /> */}
    <Text.H1>404: Not Found</Text.H1>
    <Text.DefaultText>You just hit a route that doesn&#39;t exist... the sadness.</Text.DefaultText>
  </Layout.ColumnLayout>
)

export default NotFoundPage
